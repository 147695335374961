import styled from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { breakpoint } from 'common/styledVars';

export const NavigationContainer = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align:center;

  @media ${mediaMin(breakpoint.desktop)} {
    height: 100px;
  }

  @media only screen and (max-width: 1280px) {
    .Navigation__Tab-kLFOFW {
      font-size: 14px !important;
    }
    
  }

  @media only screen and (max-width:768px) {
    display: none;
  }
`;

