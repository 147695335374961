import { DropdownLinkData, LinkData, SubLinkData } from 'models/Links';

const ourStory: LinkData = { link: '/our-story', value: 'Our Story' };
const blog: LinkData = { link: '/blog/', value: 'Blog' };
const partners: LinkData = { link: '/partner-couriers-and-warehousing/', value: 'Partners' };
const globalNetwork: LinkData = { value: 'Global Network', link: '/our-story/global-network/'};
const serviceFba: LinkData = { link: '/services/fba-prep-and-forwarding/', value: 'FBA Prep & Forwarding' };
const serviceEcommerce: LinkData = { link: '/services/ecommerce-fulfillment/', value: 'Ecommerce Fulfillment' };
const serviceAmazon: LinkData = { link: '/services/amazon-distribution-center/', value: 'Amazon Distribution Center' };
// const serviceChina: LinkData = { link: '/services/china-air-freight/', value: 'China Air Freight' };
const serviceUs: LinkData = { link: '/services/us-customs-clearance/', value: 'US Customs Clearance' };
const serviceShipment: LinkData = { link: '/services/express-shipping/', value: 'Express Shipping' };
const serviceDropshipping: LinkData = { link: '/services/dropshipping/', value: 'Dropshipping' };
const serviceSourcing: LinkData = { link: '/services/sourcing/', value: 'Sourcing' };
const serviceCrowdfunding: LinkData = { link: '/services/last-mile-delivery/', value: 'Last Mile Delivery' };
const integrations: LinkData = { value: 'Integrations', link: '/ecommerce-store-integration/' };
const serviceSourcingFromChina: LinkData = { link: '/services/shipping-from-china/', value: 'Shipping From China' };
export const contact: LinkData = { value: 'Contact', link: '/contact/' };
const pricing: LinkData = { value: 'Pricing', link: '/pricing/' };
const facebook: LinkData = { value: 'Facebook', link: 'https://www.facebook.com/Shipkoo.Shipping' };
const linkedIn: LinkData = {
  value: 'LinkedIn',
  link: 'https://linkedin.com/company/shipkoo',
};
const ourStoryChinese: LinkData = { link: '/zh/our-story/', value: '我们的故事' };
const blogChinese: LinkData = { link: '/blog/', value: '行业资讯' };
const partnersChinese: LinkData = { link: '/zh/partner-couriers-and-warehousing/', value: '合作伙伴' };
const globalNetworkChinese: LinkData = { value: '全球网络', link: '/zh/our-story/global-network/'};
const serviceFbaChinese: LinkData = { link: '/zh/services/fba-prep-and-forwarding/', value: '亚马逊物流准备与头程运输' };
const serviceEcommerceChinese: LinkData = { link: '/zh/services/ecommerce-fulfillment', value: '电子商务仓配' };
const serviceAmazonChinese: LinkData = { link: '/services/amazon-distribution-center/', value: '亞馬遜配送中心' };
// const serviceChinaChinese: LinkData = { link: '/services/china-air-freight/', value: '中國空運' };
const serviceUsChinese: LinkData = { link: '/services/us-customs-clearance/', value: '美國清關' };
const serviceShipmentChinese: LinkData = { link: '/zh/services/express-shipping/', value: '快递运输' };
const serviceDropshippingChinese: LinkData = { link: '/zh/services/dropshipping/', value: '一件代发' };
const serviceSourcingChinese: LinkData = { link: '/zh/services/sourcing/', value: '采购服务' };
const serviceCrowdfundingChinese: LinkData = { link: '/zh/services/last-mile-delivery/', value: '最后一英里交付' };
const serviceSourcingFromChinaChinese: LinkData = { link: '/zh/services/shipping-from-china/', value: '从中国发货' };

const apiOrderChinese: LinkData = { value: '订购API', link: '/zh/ecommerce-store-integration/api-docs/orders-api/'};
const gettingStartedChinese: LinkData = { value: '入门', link: '/zh/ecommerce-store-integration/api-docs/orders-shipments-api/'};
const apiProductsChinese: LinkData = { value: '产品API', link: '/zh/ecommerce-store-integration/api-docs/products-api/'};

const ourStoryKorean: LinkData = { link: '/kr/our-story/', value: '쉽쿠에 대해' };
const blogKorean: LinkData = { link: '/blog/', value: '블로그' };
const partnersKorean: LinkData = { link: '/kr/partner-couriers-and-warehousing/', value: '파트너' };
const globalNetworkKorean: LinkData = { value: '글로벌 네트워크', link: '/kr/our-story/global-network/'};

const ourStoryJapanese: LinkData = { link: '/ja/our-story/', value: '事業内容' };
const blogJapanese: LinkData = { link: '/blog/', value: 'ブログ' };
const partnersJapanese: LinkData = { link: '/ja/partner-couriers-and-warehousing/', value: 'パートナー' };
const globalNetworkJapanese: LinkData = { value: '拠点情報', link: '/ja/our-story/global-network/'};

const serviceShipmentJapanese: LinkData = { link: '/ja/services/express-shipping/', value: 'エクスプレス配送' };
const serviceEcommerceJapanese: LinkData = { link: '/ja/services/ecommerce-fulfillment/', value: 'ECフルフィルメント' };
const serviceAmazonJapanese: LinkData = { link: '/services/amazon-distribution-center/', value: 'アマゾン配送センター' };
// const serviceChinaJapanese: LinkData = { link: '/services/china-air-freight/', value: '中国航空貨物' };
const serviceUsJapanese: LinkData = { link: '/services/us-customs-clearance/', value: '米国の通関手続き' };
const serviceFbaJapanese: LinkData = { link: '/ja/services/fba-prep-and-forwarding/', value: 'FBAの準備と最初の輸送' };
const serviceDropshippingJapanese: LinkData = { link: '/ja/services/dropshipping/', value: 'ドロップシッピング' };
const serviceSourcingJapanese: LinkData = { link: '/ja/services/sourcing/', value: 'ソーシングサービス' };
const serviceCrowdfundingJapanese: LinkData = { link: '/ja/services/last-mile-delivery/', value: 'ラストマイル配達' };
const serviceSourcingFromChinaJapanese: LinkData = { link: '/ja/services/last-mile-delivery/', value: '中国からの発送' };

const gettingStartedJapanese: LinkData = { value: '入門', link: '/ja/ecommerce-store-integration/api-docs/orders-shipments-api/'};
const apiProductsJapanese: LinkData = { value: '製品API', link: '/ja/ecommerce-store-integration/api-docs/products-api/'};
const apiOrdersJapanese: LinkData = { value: '注文API', link: '/ja/ecommerce-store-integration/api-docs/orders-api/'};

const serviceShipmentKorean: LinkData = { link: '/kr/services/express-shipping/', value: '국제 특송' };
const serviceEcommerceKorean: LinkData = { link: '/kr/services/ecommerce-fulfillment', value: '전자상거래 풀필먼트' };
const serviceFbaKorean: LinkData = { link: '/kr/services/fba-prep-and-forwarding/', value: 'FBA 프렙 및 포워딩' };
const serviceAmazonKorean: LinkData = { link: '/services/amazon-distribution-center/', value: '아마존 유통 센터' };
// const serviceChinaKorean: LinkData = { link: '/services/china-air-freight/', value: '중국항공운임' };
const serviceUsKorean: LinkData = { link: '/services/us-customs-clearance/', value: '미국 통관' };
const serviceSourcingKorean: LinkData = { link: '/kr/services/sourcing/', value: '소싱 서비스' };
const serviceDropshippingKorean: LinkData = { link: '/kr/services/dropshipping/', value: '드랍쉬핑' };
const serviceCrowdfundingKorean: LinkData = { link: '/kr/services/last-mile-delivery/', value: '라스트 마일 배송' };
const serviceSourcingFromChinaKorean: LinkData = { link: '/kr/services/shipping-from-china/', value: '중국에서 배송' };

const gettingStartedKorean: LinkData = { value: '시작하기', link: '/kr/ecommerce-store-integration/api-docs/orders-shipments-api/'};
const apiProductsKorean: LinkData = { value: '제품 API', link: '/kr/ecommerce-store-integration/api-docs/products-api/'};
const apiOrdersKorean: LinkData = { value: '오더 API', link: '/kr/ecommerce-store-integration/api-docs/orders-api/'};

const platform: LinkData = { value: 'Platform Integrations', link: '/ecommerce-store-integration/' };
const apiDocs: LinkData = { value: 'API Docs', link: '/ecommerce-store-integration/api-docs/orders-shipments-api/' };
const gettingStarted: LinkData = { value: 'Getting Started', link: '/ecommerce-store-integration/api-docs/orders-shipments-api/'};
const apiProducts: LinkData = { value: 'Products API', link: '/ecommerce-store-integration/api-docs/products-api/'};
const apiOrders: LinkData = { value: 'Orders API', link: '/ecommerce-store-integration/api-docs/orders-api/'};
const blog1: LinkData = { value: '1', link: '/blog/'};
const blog2: LinkData = { value: '2', link: '/blog/2'};
const blog3: LinkData = { value: '3', link: '/blog/3'};
const blog4: LinkData = { value: '4', link: '/blog/4'};
const blog5: LinkData = { value: '5', link: '/blog/5'};
const blog6: LinkData = { value: '6', link: '/blog/6'};
const blogLast: LinkData = { value: '>>', link: '/blog/4'};
const blogFirst: LinkData = { value: '<<', link: '/blog/'};
export const BlogPagesLinks: LinkData[] = [blogFirst, blog1, blog2, blog3, blog4, blogLast];
export const aboutUsLinks: LinkData[] = [ourStory, blog, partners];
export const aboutUs_Links: LinkData[] = [ourStory, blog, partners];
// export const servicesLinks: LinkData[] = [serviceFba, serviceShipment, serviceEcommerce, serviceCrowdfunding, serviceSourcing, serviceAmazon, serviceUs];
export const servicesLinks: LinkData[] = [serviceFba, serviceShipment, serviceEcommerce, serviceCrowdfunding, serviceSourcing, serviceAmazon, serviceUs, serviceSourcingFromChina];
// export const servicesLinks: LinkData[] = [serviceFba, serviceShipment, serviceEcommerce, serviceCrowdfunding, serviceSourcing];
export const featureLinks: LinkData[] = [contact,  platform, apiDocs];
export const socialMediaLinks: LinkData[] = [facebook, linkedIn];
export const integrationLinks: LinkData[] = [platform, apiDocs];
export const apiDocsLinks: LinkData[] = [gettingStarted, apiProducts, apiOrders];
export const companyLinks: LinkData[] = [ourStory, blog, partners, ];

export const aboutUsLinksChinese: LinkData[] = [ourStoryChinese, blogChinese, partnersChinese];
// export const servicesLinksChinese: LinkData[] = [serviceFbaChinese, serviceShipmentChinese, serviceEcommerceChinese, serviceCrowdfundingChinese, serviceSourcingChinese, serviceAmazonChinese, serviceUsChinese];
export const servicesLinksChinese: LinkData[] = [serviceFbaChinese, serviceShipmentChinese, serviceEcommerceChinese, serviceCrowdfundingChinese, serviceSourcingChinese, serviceAmazonChinese, serviceUsChinese, serviceSourcingFromChinaChinese];
// export const servicesLinksChinese: LinkData[] = [serviceFbaChinese, serviceShipmentChinese, serviceEcommerceChinese, serviceCrowdfundingChinese, serviceSourcingChinese];
export const apiDocsLinksChinese: LinkData[] = [gettingStartedChinese, apiProductsChinese, apiOrderChinese];

export const aboutUsLinksKorean: LinkData[] = [ourStoryKorean, blogKorean, partnersKorean];
// export const servicesLinksKorean: LinkData[] = [serviceFbaKorean, serviceShipmentKorean, serviceEcommerceKorean, serviceCrowdfundingKorean, serviceSourcingKorean, serviceAmazonKorean, serviceUsKorean];
export const servicesLinksKorean: LinkData[] = [serviceFbaKorean, serviceShipmentKorean, serviceEcommerceKorean, serviceCrowdfundingKorean, serviceSourcingKorean, serviceAmazonKorean, serviceUsKorean, serviceSourcingFromChinaKorean];
// export const servicesLinksKorean: LinkData[] = [serviceFbaKorean, serviceShipmentKorean, serviceEcommerceKorean, serviceCrowdfundingKorean, serviceSourcingKorean];
export const apiDocsLinksKorean: LinkData[] = [gettingStartedKorean, apiProductsKorean, apiOrdersKorean];

export const aboutUsLinksJapanese: LinkData[] = [ourStoryJapanese, blogJapanese, partnersJapanese];
// export const servicesLinksJapanese: LinkData[] = [serviceFbaJapanese, serviceShipmentJapanese, serviceEcommerceJapanese, serviceCrowdfundingJapanese, serviceSourcingJapanese, serviceAmazonJapanese, serviceUsJapanese];
export const servicesLinksJapanese: LinkData[] = [serviceFbaJapanese, serviceShipmentJapanese, serviceEcommerceJapanese, serviceCrowdfundingJapanese, serviceSourcingJapanese, serviceAmazonJapanese, serviceUsJapanese, serviceSourcingFromChinaJapanese];
// export const servicesLinksJapanese: LinkData[] = [serviceFbaJapanese, serviceShipmentJapanese, serviceEcommerceJapanese, serviceCrowdfundingJapanese, serviceSourcingJapanese];
export const apiDocsLinksJapanese: LinkData[] = [gettingStartedJapanese, apiProductsJapanese, apiOrdersJapanese];

export const navigationLinks: DropdownLinkData[] = [
  {
    value: 'About Us',
    link: '/our-story/',
    options: aboutUs_Links,
  },
  {
    value: 'Services',
    link: serviceShipment.link,
    options: servicesLinks,
  },
  {
    value: 'Integrations',
    link: '/ecommerce-store-integration/',
    options: integrationLinks,
  },
];
