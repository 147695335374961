import React from 'react';

import Navigation, { NavigationProps } from 'components/Navigation';
import { NavigationContainer } from './styled';

const TabNavigator = ({ tabs, activeTab }: NavigationProps) => (
  <NavigationContainer>
    <Navigation tabs={tabs} activeTab={activeTab} />
  </NavigationContainer>
  
);

export default TabNavigator;
