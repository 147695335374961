import React from 'react';
import styled from 'styled-components';

import { color } from 'common/styledVars';

const BigDotWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: -34px;
  bottom: -80px;
  z-index: 10;

  div {
    position: -webkit-sticky;
    position: sticky;
    top: 50%;
    height: 68px;
    width: 68px;
    background-color: #f8f8f8;
    transform: translate(-50%);
    z-index: 9;
    border-radius: 50%;
  }
`;

const SmallDotWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: -10px;
  bottom: -56px;
  z-index: 20;

  div {
    position: -webkit-sticky;
    position: sticky;
    top: calc(50% + 24px);
    height: 20px;
    width: 20px;
    transform: translate(-50%);
    z-index: 20;
    border-radius: 50%;
    background-color: ${color.green.persian};
  }
`;

const LineVertical = styled.div`
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  top: 0;
  bottom: -50px;
  background-color: #f0f0f0;
  z-index: 10;
`;

const TemporaryDiv = styled.div`
  @media only screen and (max-width: 760px),
  (min-width: 472px) and (max-width: 1024px) {

    ${SmallDotWrapper}{
      display:none;
    }

    ${LineVertical}{
      display:none;
    }

    div {
      display: none;
    }
  }
`;

const Dots = () => {
  return (
    <>
      <TemporaryDiv>
      <BigDotWrapper>
        <div />
      </BigDotWrapper>
      <SmallDotWrapper>
        <div />
      </SmallDotWrapper>
      <LineVertical />
      </TemporaryDiv>
    </>
  );
};

export default Dots;
