import React, { useEffect, useRef, useState, useCallback, RefObject } from 'react';
import styled, { css } from 'styled-components';

import { mediaMin, mediaMax } from 'common/styledMixins';
import { breakpoint, color } from 'common/styledVars';
import { BaseP, HeaderMedium, MediumTextTransparent } from 'components/typography';
import { InfinityIcon } from 'components/icons';
import { WidthWrapper } from 'components/wrappers';
import Dots from './Dots';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const InfIcon = styled(InfinityIcon)`
  height: 100%;
  width: 100%;
`;

const HeaderSmall = styled.div`
  opacity: 0.9;
  font-size: 21px;
  font-weight: 500;
  margin: 8px 0;

  @media only screen and (min-width: 1024px) {
    margin: 15px 0;
    font-size: 26px;
  }
`;

const BasePa = styled.h2`
  font-size: 14px;
  font-weight: 300;
  color: #00AF85;

  @media only screen and (min-width: 1024px) {
    font-size: 16px;
  }
`;

const IconWrapper = styled.div`
  height: 0;
  position: relative;
  width: 100%;
  height: 1px;
  margin-top: 75px;

  div {
    position: absolute;
    left: 36vw;
    bottom: -485px;
    right: 0;
  }
`;

const ProcessText = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;

  @media ${mediaMin(breakpoint.desktop)} {
    font-size: 20px;
    line-height: 35px;
  }
`;

const Container = styled(WidthWrapper)`
  margin-top: 55px;
  position: initial;
  max-width: 1300px;
  ${HeaderMedium} {
    margin: 16px 0 28px;
  }


  @media ${mediaMin(breakpoint.desktop)} {
    margin-top: 143px;

    ${HeaderMedium} {
      margin: 45px 0 100px;
    }
  }
`;

const ItemBody = styled.div<{ active?: boolean }>`
  z-index: 15;

  ${HeaderSmall} {
    margin: 8px 0;
  }

  ${ProcessText} {
    margin-bottom: 35px;
  }

  ${MediumTextTransparent} {
    max-width: 380px;
    font-size:18px;
  }

  &:nth-child(2n) {
    left: calc(50% + 100px);
  }

  @media ${mediaMin(breakpoint.desktop)} {
    position: absolute;
    height: 200px;
    width: calc(50% - 100px);
    left: 0;

    ${HeaderSmall} {
      margin: 15px 0;
    }
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: -50px;
      background-color: ${color.green.persian};
      box-shadow: 0 2px 24px 0 rgba(0, 175, 133, 0.36);
      border-radius: 2px;
      opacity: 0;
      transition: all 0.3s ease-in;
    }

    ${props =>
      props.active &&
      css`
        &:after {
          opacity: 1;
        }
      `}
  }
`;

interface ItemProps {
  children: React.ReactNode;
  active?: boolean;
}

const Item = ({ children, active }: ItemProps) => (
  <li className="service-list">
    <ItemBody active={active}>{children}</ItemBody>
  </li>
);

const TopDot = styled.div`
  height: 13px;
  width: 13px;
  background-color: #ecf0ef;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
`;

const TimelineContainer = styled.ul`
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 100%;

  li {
    list-style-type: none;
    width: 100%;
    margin: 0 auto;
  }


  .step-desc .en .zh {
    display:none;
  }

  .step-desc .en .kr {
    display:none;
  }

  .step-desc .en .ja {
    display:none;
  }

  .step-desc .zh .en {
    display: none;
  }

  .step-desc .zh .kr {
    display:none;
  }

  .step-desc .zh .ja {
    display:none;
  }

  .step-desc .kr .en {
    display:none;
  }

  .step-desc .kr .zh {
    display:none;
  }

  .step-desc .kr .ja {
    display:none;
  }

  .step-desc .ja .en {
    display:none;
  }

  .step-desc .ja .zh {
    display:none;
  }

  .step-desc .ja .kr {
    display:none;
  }


  .service-list:nth-child(odd) .step-img {
    width: 42%;
    float: left;
    display: inline-block;
    margin-top: 5px;
    margin-right: 4%;
  }

  .service-list:nth-child(even) .step-img {
    width: 42%;
    float: left;
    display: inline-block;
    margin-top: 5px;
  }

  .service-list:nth-child(odd) .step-desc {
    width: 54%;
    float: right;
    display: inline-block;
  }

  .service-list:nth-child(even) .step-desc {
    float: left;
    display: inline-block;
    width: 54%;
    margin-right: 4%;
  }

  .step-img img {
    width:100%;
  }
  @media only screen and (max-width:600px) {
    .service-list .step-desc {
      width: 100% !important;
      display: block !important;
      float: none !important;
    }

    .service-list .step-img { 
      width: 100% !important;
      display: block !important;
      text-align: center !important;
      float: none !important;
    }

    .step-img img {
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  @media ${mediaMax(breakpoint.desktop - 1)} {
    margin-top: -60px;

    & > div {
      display: none;
    }

    li {
      margin-top: 60px;
      margin-bottom:-40px;
    }
  }

  @media ${mediaMin(breakpoint.desktop)} {
    margin-bottom: 170px;
    li {
      list-style-type: none;
      width: 4px;
      margin: 0 auto;
      background: #f0f0f0;
      height: 200px;

      &:nth-child(2n + 1) div:after {
        left: calc(100% + 98px);
        width: 4px;
      }

      &:nth-child(2n) div:after {
        left: -102px;
        width: 4px;
      }

      &:nth-child(even) {
        ${ItemBody} {
          left: calc(50% + 100px);
        }
      }
    }
  }
`;

export interface TimelineItem {
  title: string;
  description: string;
  stepZh: string;
  image: ReactElementType;
}

interface OwnProps {
  timelineItems: TimelineItem[];
  numSteps: string;
}

const Timeline = ({ timelineItems }: OwnProps) => {
  const [active, setActive] = useState(0);
  const ref = useRef(null);

  const handleScroll = useCallback(() => {
    if (ref.current) {
      const scrollFromContainerFront =
        window.innerHeight / 2 + window.scrollY - ref.current.offsetTop;
      if (scrollFromContainerFront > 0 && scrollFromContainerFront < ref.current.clientHeight) {
        const activeItem = Math.floor(scrollFromContainerFront / 200);
        setActive(activeItem);
      }
    }
  }, [ref, setActive]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll as EventListener);
    return () => window.removeEventListener('scroll', handleScroll as EventListener);
  }, [handleScroll]);
  const intl = useIntl()
  return (
    <TimelineContainer ref={ref}>
      {timelineItems.map((item, i) => (
        <Item key={i} {...(i === active ? { active: true } : {})}>
        <div className="step-desc">
            <div className={intl.formatMessage({ id: "language" })}>
            <BaseP className="en">{item.titleStep}</BaseP>
            <HeaderSmall className="en">{item.title}</HeaderSmall>
            <MediumTextTransparent className="en">{item.description}</MediumTextTransparent>

            <BaseP className="zh">{item.titleStepZH}</BaseP>
            <HeaderSmall className="zh">{item.titleZH}</HeaderSmall>
            <MediumTextTransparent className="zh">{item.descriptionZH}</MediumTextTransparent>

            <BaseP className="kr">{item.titleStepKO}</BaseP>
            <HeaderSmall className="kr">{item.titleKO}</HeaderSmall>
            <MediumTextTransparent className="kr">{item.descriptionKO}</MediumTextTransparent>

            <BaseP className="ja">{item.titleStepJP}</BaseP>
            <HeaderSmall className="ja">{item.titleJP}</HeaderSmall>
            <MediumTextTransparent className="ja">{item.descriptionJP}</MediumTextTransparent>
          </div>
        </div>
        <div className="step-img">
          <img src={item.image} alt={item.alt} />
        </div>  
        </Item>
      ))}
      <Dots />
      <TopDot />
    </TimelineContainer>
  );
};

const Process = ({
  timelineItems,
  innerRef,
  numSteps,
}: OwnProps & {
  innerRef: RefObject<any>;
}) => {
  const intl = useIntl()
  return (
    <>
      <IconWrapper>
        <div>
          <InfIcon />
        </div>
      </IconWrapper>
      <Container ref={innerRef}>
        <BasePa>{intl.formatMessage({ id: "express-shipping-process-title" })}</BasePa>
        <HeaderMedium>
          {numSteps}
        </HeaderMedium>
        <Timeline timelineItems={timelineItems} />
      </Container>
    </>
  );
};

export default Process;
