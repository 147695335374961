import React from 'react';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { mediaMin, mediaMax } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import { HeaderSmall, HeaderMedium, BaseTransparentP } from 'components/typography';
import PlanetIcon from 'assets/save-planet.svg';
import DeliveryIcon from 'assets/delivery-time.svg';
import WireframeIcon from 'assets/wireframe.svg';

const CardBody = styled.div`
  position: relative;
  min-height: 265px;
  padding: 100px 20px 32px;
  display: flex;
  flex-direction: column;
  align-items: bottom;
  justify-content: space-between;
  box-shadow: 0 23px 34px 0 rgba(0, 0, 0, 0.17);
  flex: 1;
  border-radius: 17px;
  background-color: #ffffff;
  box-shadow: 0 23px 100px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  @media ${mediaMin(breakpoint.desktop)} {
    padding: 100px 30px 42px;
  }
`;

const CardsWrapper = styled.div`
  margin-left: -20px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: ${zIndex.body + 5};
  padding-top:40px;
  ${CardBody} {
    margin-left: 20px;
    margin-top: 20px;
  }
  @media ${mediaMax(breakpoint.desktop - 1)} {
    margin-top: 32px;
    ${CardBody} {
      width: 100%;
      margin-top:50px;
    }
  }
  @media ${mediaMin(breakpoint.desktop)} {
    flex-direction: row;
  }
`;

const IconBox = styled.div`
  border-radius: 50%;
  height: 130px;
  width: 130px;
  background-color: #eff7f6;
  box-shadow: 0 22px 100px 0 #b5c6c2;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 15px 18px 15px;
`;

interface OwnProps {
  cards: string[];
  cardsTitle: string[];
  IconCardOne: React.ElementType;
  IconCardTwo: React.ElementType;
  IconCardThree: React.ElementType;
}

const Cards = ({ cards, cardsTitle, IconCardOne, IconCardTwo, IconCardThree }: OwnProps) => {
  const intl = useIntl()
  return (
    <CardsWrapper>
      <CardBody>
        <IconBox>
          <IconCardOne />
        </IconBox>
        <h3>{intl.formatMessage({ id: "fba-pref-one-stop-shop-title" })}</h3>
        <BaseTransparentP>{intl.formatMessage({ id: "fba-pref-one-stop-shop-description" })}</BaseTransparentP>
      </CardBody>
      <CardBody>
        <IconBox>
          <IconCardTwo />
        </IconBox>
        <h3>{intl.formatMessage({ id: "fba-pref-warehouse-coverage-title" })}</h3>
        <BaseTransparentP>{intl.formatMessage({ id: "fba-pref-warehouse-coverage-description" })}</BaseTransparentP>
      </CardBody>
      <CardBody>
        <IconBox>
          <IconCardThree />
        </IconBox>
        <h3>{intl.formatMessage({ id: "fba-pref-affordable-price-title" })}</h3>
        <BaseTransparentP>{intl.formatMessage({ id: "fba-pref-affordable-price-description" })}</BaseTransparentP>
      </CardBody>
    </CardsWrapper>
  );
};

export default Cards;