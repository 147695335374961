import React from 'react';
import styled from 'styled-components';
import {Link} from "gatsby-plugin-intl";
import { mediaMin } from 'common/styledMixins';
import { breakpoint } from 'common/styledVars';
import { LinkData } from 'models/Links';
import { baseFontStyle } from 'components/typography';

const Container = styled.div`
  display: flex;
  justify-content: center;

  & > div:not(:first-of-type) {
    margin-left: 5px;
  }
`;

interface TabProps {
  active: boolean;
}

const Tab = styled.div<TabProps>`
  ${baseFontStyle};
  opacity: ${props => (props.active ? 1 : 0.5)};

  div {
    padding: 5px;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    a {
      white-space: nowrap;
    }
  }
`;

export interface NavigationProps {
  tabs: LinkData[];
  activeTab: string;
}

const Navigation = ({ tabs, activeTab }: NavigationProps) => {
  return (
    <Container>
      {tabs.map(tab => (
        <Tab key={tab.value} active={tab.value === activeTab}>
          <a href={tab.link}>
            <div>{tab.value}</div>
          </a>
        </Tab>
      ))}
    </Container>
  );
};

export default Navigation;
