import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { servicesLinks } from 'components/Layout/navigation';
import Service from 'components/Service_FBA';
import { ImgGatsbyBackground } from 'components/wrappers';
import Icon1 from 'images/icon1_fba2.png';
import Icon2 from 'images/icon2_fba2.png';
import Icon3 from 'images/icon3_fba2.png';
import Step1 from 'images/steps/fba-prep/prepare-shipment.png';
import Step2 from 'images/steps/fba-prep/order.png';
import Step3 from 'images/steps/fba-prep/request-dropoff.png';
import Step4 from 'images/steps/fba-prep/package-registration.png';
import Step5 from 'images/steps/fba-prep/deliver-to-fba-warehouse.png';
import Step6 from 'images/steps/fba-prep/tracking.png';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query ImgFBAQuery {
      file(relativePath: { eq: "FBA_Prep_and_Forwarding2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo amazon fba logistics preparation and shipping service" />;
  const IconCardOne = () => <img src={Icon1} alt="Icon" />;
  const IconCardTwo = () => <img src={Icon2} alt="Icon" />;
  const IconCardThree = () => <img src={Icon3} alt="Icon" />;
  const StepOne = () => <img src={Step1} alt="Icon" />;
  const intl = useIntl()
  return (
    <Service
      BackgroundImage={BackgroundImage}
      IconCardOne={IconCardOne}
      IconCardTwo={IconCardTwo}
      IconCardThree={IconCardThree}
      navigationTabs={servicesLinks}
      activeTab={servicesLinks[0].value}
      description={
        <>
          {intl.formatMessage({ id: "fba-pref-header-description" })}
        </>
      }
      header={
        <>
          {intl.formatMessage({ id: "fba-pref-sub-header-description" })}
        </>
      }
      about={intl.formatMessage({ id: "fba-pref-header" })}
      cardsTitle={[
        'A One-Stop Shop',
        'Warehouse Coverage',
        'Affordable Price',
      ]}
      cards={[
        'A one-stop shop: We take your shipments from the factory and prepares them to fit Amazon’s standards',
        'Shipkoo handles sea, air, and railway forwarding services, delivering packages to FBA warehouses all over the world',
        'Shipkoo takes care of all your logistics needs, finding the lowest rates for every level of service you desire',
      ]}
      numSteps={intl.formatMessage({ id: "fba-pref-how-it-works-explained" })}
      timelineItems={[
        {
          titleStep: 'Step 1',
          title: 'Prepare shipment',
          description:
          'Prepare your FBA shipment on your own or by using Shipkoo’s FBA Prep service',
          image: Step1,
          alt: 'shipkoo amazon logistics fba shipping preparations',
          titleStepZH: '第一步',
          titleZH: '准备运送',
          descriptionZH: '自行准备或通过Shipkoo酷运的FBA Prep服务准备货物',
          titleStepKO: '1단계',
          titleKO: '선적준비',
          descriptionKO: '직접 또는 Shipkoo의 FBA 준비 서비스를 사용하여 FBA 발송 준비',
          titleStepJP: 'ステップ1',
          titleJP: '発送準備',
          descriptionJP: '自分で、またはShipkooのFBA Prepサービスを使用して、FBA出荷を準備します',
        },
        {
          titleStep: 'Step 2',
          title: 'Order',
          description: 'Place an order in Shipkoo’s FBA system with your FBA shipment ID',
          image: Step2,
          alt: 'shipkoo amazon logistics fba order placement',
          titleStepZH: '第二步',
          titleZH: '下单',
          descriptionZH: '使用您的FBA货件ID 在Shipkoo酷运的FBA系统中下订单',
          titleStepKO: '2단계',
          titleKO: '순서',
          descriptionKO: 'Shipkoo의 FBA 시스템에서 FBA 발송 ID를 사용하여 주문하십시오.',
          titleStepJP: 'ステップ2',
          titleJP: '注文',
          descriptionJP: 'ShipkooのFBAシステムでFBA出荷IDを使用して注文する',
        },
        {
          titleStep: 'Step 3',
          title: 'Request a drop-off',
          description: 'Request a local pickup or drop off for your FBA cartons at Shipkoo’s nearest hub',
          image: Step3,
          alt: 'shipkoo amazon logistics fba local pickup dropoff request',
          titleStepZH: '第三步',
          titleZH: '运输包裹',
          descriptionZH: '上门运输或安排当地物流流配送货物至Shipkoo酷运就近的仓配中心',
          titleStepKO: '3단계',
          titleKO: '하차 요청',
          descriptionKO: 'Shipkoo의 가장 가까운 허브에 있는 FBA 상자에 대한 로컬 픽업 또는 드롭 다운 요청',
          titleStepJP: 'ステップ3',
          titleJP: '降車をリクエストする',
          descriptionJP: 'Shipkooの最寄りの倉庫配送センターに商品を配送するためのドアツードア輸送またはローカルロジスティクスの手配',
        },
        {
          titleStep: 'Step 4',
          title: 'Package registration',
          description: 'Shipkoo registers your package and processes its weight and dimensions',
          image: Step4,
          alt: 'shipkoo fba shipping logistics package registration',
          titleStepZH: '第四步',
          titleZH: '登记包裹',
          descriptionZH: 'Shipkoo酷运 识别您的包裹并处理其重量和尺寸',
          titleStepKO: '4단계',
          titleKO: '패키지등록',
          descriptionKO: 'Shipkoo는 당신의 패키지를 등록하고 그것의 무게와 치수를 처리한다.',
          titleStepJP: 'ステップ4',
          titleJP: 'パッケージ登録',
          descriptionJP: 'Shipkooはパッケージを認識し、その重量と寸法を処理します',
        },
        {
          titleStep: 'Step 5',
          title: 'Delivery to FBA Prep Warehouse',
          description: 'Shipkoo palletizes your package, handles customs clearance and ships it to the destination country, and then delivers the shipments to a designated Amazon FBA warehouse',
          image: Step5,
          alt: 'shipkoo fba shipping logistics warehouse delivery',
          titleStepZH: '第五步',
          titleZH: '送货到FBA准备仓库',
          descriptionZH: 'Shipkoo酷运打包您的包裹，处理清关，空运运输到目的地国家，然后将货物送到指定的亚马逊FBA仓库',
          titleStepKO: '5단계',
          titleKO: 'FBA 준비 창고로 배송',
          descriptionKO: 'Shipkoo는 당신의 소포를 팔레트화하여 통관을 처리하고 목적지 국가까지 배송한 후 지정된 아마존 FBA 창고로 배송한다.',
          titleStepJP: 'ステップ5',
          titleJP: 'FBA梱包倉庫への配送',
          descriptionJP: 'Shipkooは荷物を梱包し、通関手続き、目的国への航空貨物を処理してから、指定されたAmazonFBA倉庫に商品を配送します。',
        },
        {
          titleStep: 'Step 6',
          title: 'Tracking',
          description: 'Use the full visibility of shipments to track your orders in the Amazon seller account and see when packages arrive',
          image: Step6,
          alt: 'shipkoo fba shipping logistics order tracking',
          titleStepZH: '步骤6',
          titleZH: '追踪',
          descriptionZH: '使用货运的全部可见性来跟踪您在Amazon卖家账户中的订单并查看包裹何时到达',
          titleStepKO: '6단계',
          titleKO: '추적',
          descriptionKO: '발송물의 전체 가시성을 사용하여 Amazon 셀러 계정의 주문을 추적하고 패키지가 언제 도착하는지 확인',
          titleStepJP: 'ステップ6',
          titleJP: '追跡',
          descriptionJP: '貨物の完全な可視性を使用して、Amazonセラーアカウントで注文を追跡し、荷物がいつ到着するかを確認します',
        },
      ]}
    />
  );
};

export default ServicesPage;
